<template>
  <Layout>
    <PageHeader :items="items" />
    <!-- Filters -->
    <div class="card" style="margin-top:-30px">
      <div class="card-body">
        <div class="inner mb-2 row">
          <!-- Country List -->
          <div v-if="this.$storageData.profile.empTypeID == 2 || this.$storageData.profile.empTypeID == 11" class="col-md-2" style="width:10%">
            <label>Country </label>
            <multiselect v-model="country" :options="countryArr" :show-labels="false" label="country" track-by="country" @input="getRestaurantCities()"></multiselect>
          </div>
          <!-- End Country List -->
          <!-- Cities List -->
          <div v-if="this.$storageData.profile.empTypeID == 2 || this.$storageData.profile.empTypeID == 11" class="col-md-2" style="width:16%">
            <label>City </label>
            <multiselect v-model="city" :options="cityArr" :show-labels="false" label="city" track-by="city" @input="getRestaurantBrandsByCompanyID()"></multiselect>
          </div>
          <!--END Cities List -->
          <!-- Restaurant Brand List -->
          <div v-if="this.$storageData.profile.empTypeID == 11" class="col-md-3 mb-3" style="width:16%">
            <label>Restaurant Brand</label>
            <multiselect v-model="brandData" :options="brandArr" :show-labels="false" label="name" track-by="restID" @input="readRestaurantBranchData()"></multiselect>
          </div>
          <!-- End Restaurant Brand List -->
          <!-- Restaurant Branch List -->
          <div v-if="this.$storageData.profile.empTypeID == 2 || this.$storageData.profile.empTypeID == 11" class="col-md-3" style="width:16%">
            <label>Restaurant Branch </label>
            <multiselect v-model="branchID" :options="branches" :show-labels="false" label="branchName" track-by="restBranchID" @input="onchangeBranch()"></multiselect>
          </div>
          <!-- End Restaurant Branch List -->
          <!-- Date Range -->
          <div class="col-md-3" >
            <label>Date Range* </label>
            <date-picker v-model="daterange" append-to-body format="DD MMM Y" lang="en" range value-type="format" @input="onchangeBranch()"></date-picker>
          </div>
          <!-- End Date Range -->
          <!-- Apply Filter -->
          <div class="col-md-2"  style="width:auto;">
            <button class="btn btn-themeOrange mt-4"  v-on:click="applyFilter()"> Apply Filter </button>
          </div>
          <!-- End Apply Filter -->
          <!-- Clear Filter -->
          <div class="col-md-2"  style="width:auto;">
            <button class="btn btn-themeBrown mt-4"  v-on:click="clearFilter()"> Clear Filter </button>
          </div>
          <!-- End Clear Filter -->
        </div>
      </div>
    </div>
    <!-- End Filters -->

    <!-- Stats end -->
    <div class="row" style="margin: 10px 0 0;">
      <div v-if="isLoading == 1" class="text-center text-danger my-2">
        <b-spinner class="align-middle"></b-spinner>
        <strong>Loading Please wait...</strong>
      </div>
      <div class="col-md-6 col-xl-3 overviewCard">
        <div class="card">
          <div class="card-body">
            <div class="float-end mt-2">
              <!-- <apexchart class="apex-charts" dir="ltr" width="70" height="40" :options="chartOptions" :series="series"></apexchart> -->
            </div>
            <div>
              <h4 class="cardTitle mb-1 mt-1">
                {{(overViewData.sumGrandTotal) ? $func.formatPrice(overViewData.sumGrandTotal) : 0}}
                <!-- <span data-plugin="counterup">
                    <countTo :startVal="1000" :endVal="(overViewData.sumGrandTotal) ? overViewData.sumGrandTotal : 0"  :duration="2000"></countTo>
                </span> -->
              </h4>
              <p class="text-muted mb-0">Total</p>
            </div>
            <div class="row">
              <p class="text-muted mt-3 mb-0 col-7 cardSubtitle1">
                <span>{{(overViewData.totalOrders) ? $func.formatCommaSepNum(overViewData.totalOrders) : 0}}</span> Orders
              </p>
              <span class="align-center col-5 cardSubtitle2">
                <p>{{(overViewData.avgSale) ? $func.formatPrice(overViewData.avgSale) : 0}}</p> AOV
              </span>
            </div>
          </div>
        </div>
      </div>

      <div class="col-md-6 col-xl-3 overviewCard">
        <div class="card">
          <div class="card-body">
            <div class="float-end mt-2"></div>
            <div>
              <h4 class="cardTitle mb-1 mt-1">
                {{(overViewData.sumDigitalGrandTotal) ? $func.formatPrice(overViewData.sumDigitalGrandTotal) : 0}}
              </h4>
              <p class="text-muted mb-0">Digital</p>
            </div>
            <div class="row">
              <p class="text-muted mt-3 mb-0 col-7 cardSubtitle1">
                <span>{{(overViewData.totalDigitalOrders) ? $func.formatCommaSepNum(overViewData.totalDigitalOrders) : 0}}</span> Orders
              </p>
              <span class="align-center col-5 cardSubtitle2">
                <p>{{(overViewData.digitalAvgSale) ? $func.formatPrice(overViewData.digitalAvgSale) : 0}}</p> AOV
              </span>
            </div>
          </div>
        </div>
      </div>

      <div class="col-md-6 col-xl-3 overviewCard">
        <div class="card">
          <div class="card-body">
            <div class="float-end mt-2">
              <!-- <apexchart class="apex-charts" dir="ltr" width="70" height="40" :options="chartOptions" :series="series"></apexchart> -->
            </div>
            <div>
              <h4 class="cardTitle mb-1 mt-1">
                {{(overViewData.sumCorpSubsidy) ? $func.formatPrice(overViewData.sumCorpSubsidy) : 0}}
              </h4>
              <p class="text-muted mb-0">Credit Meal Plan</p>
            </div>
            <div class="row">
              <p class="text-muted mt-3 mb-0 col-7 cardSubtitle1">
                <span>{{(overViewData.totalMealOrders) ? $func.formatCommaSepNum(overViewData.totalMealOrders) : 0}}</span> Orders
              </p>
              <span class="align-center col-5 cardSubtitle2">
                <p>{{(overViewData.mealPlanAvgSale) ? $func.formatPrice(overViewData.mealPlanAvgSale) : 0}}</p> AOV
              </span>
            </div>
          </div>
        </div>
      </div>

      <div class="col-md-6 col-xl-3 overviewCard">
        <div class="card">
          <div class="card-body">
            <div class="float-end mt-2">
              <!-- <apexchart class="apex-charts" dir="ltr" width="70" height="40" :options="chartOptions" :series="series"></apexchart> -->
            </div>
            <div>
              <h4 class="cardTitle mb-1 mt-1">
                {{(overViewData.sumWallet) ? $func.formatNumber(overViewData.sumWallet) : 0}}
              </h4>
              <p class="text-muted mb-0">Wallet</p>
            </div>
            <div class="row">
              <p class="text-muted mt-3 mb-0 col-7 cardSubtitle1">
                <span>{{(overViewData.totalWalletOrders) ? $func.formatCommaSepNum(overViewData.totalWalletOrders) : 0}}</span> Orders
              </p>
              <span class="align-center col-5 cardSubtitle2">
                <p>{{(overViewData.walletAvgSale) ? $func.formatNumber(overViewData.walletAvgSale) : 0}}</p> AOV
              </span>
            </div>
          </div>
        </div>
      </div>

      <div class="col-md-6 col-xl-3 overviewCard">
        <div class="card">
          <div class="card-body">
            <div class="float-end mt-2">
              <!-- <apexchart class="apex-charts" dir="ltr" width="70" height="40" :options="chartOptions" :series="series"></apexchart> -->
            </div>
            <div>
              <h4 class="cardTitle mb-1 mt-1">
                {{(overViewData.sumVoucher) ? $func.formatPrice(overViewData.sumVoucher) : 0}}
              </h4>
              <p class="text-muted mb-0">Voucher</p>
            </div>
            <div class="row">
              <p class="text-muted mt-3 mb-0 col-7 cardSubtitle1">
                <span>{{(overViewData.totalVouchersOrders) ? $func.formatCommaSepNum(overViewData.totalVouchersOrders) : 0}}</span> Orders
              </p>
              <span class="align-center col-5 cardSubtitle2">
                <p>{{(overViewData.voucherAvgSale) ? $func.formatPrice(overViewData.voucherAvgSale) : 0}}</p> AOV
              </span>
            </div>
          </div>
        </div>
      </div>

      <div class="col-md-6 col-xl-3 overviewCard">
        <div class="card">
          <div class="card-body">
            <div class="float-end mt-2">
              <!-- <apexchart class="apex-charts" dir="ltr" width="70" height="40" :options="chartOptions" :series="series"></apexchart> -->
            </div>
            <div>
              <h4 class="cardTitle mb-1 mt-1">
                {{(overViewData.sumCoupon) ? $func.formatPrice(overViewData.sumCoupon) : 0}}
              </h4>
              <p class="text-muted mb-0">Coupon</p>
            </div>
            <div class="row">
              <p class="text-muted mt-3 mb-0 col-7 cardSubtitle1">
                <span>{{(overViewData.totalCouponsOrders) ? $func.formatCommaSepNum(overViewData.totalCouponsOrders) : 0}}</span> Orders
              </p>
              <span class="align-center col-5 cardSubtitle2">
                <p>{{(overViewData.couponAvgSale) ? $func.formatPrice(overViewData.couponAvgSale) : 0}}</p> AOV
              </span>
            </div>
          </div>
        </div>
      </div>

      <div class="col-md-6 col-xl-3 overviewCard">
        <div class="card">
          <div class="card-body">
            <div class="float-end mt-2">
              <!-- <apexchart class="apex-charts" dir="ltr" width="70" height="40" :options="chartOptions" :series="series"></apexchart> -->
            </div>
            <div>
              <h4 class="cardTitle mb-1 mt-1">
                {{(overViewData.sumTaxes) ? $func.formatPrice(overViewData.sumTaxes) : 0}}
              </h4>
              <p class="text-muted mb-0">Taxes for Ecom</p>
            </div>
            <div class="row">
              <p class="text-muted mt-3 mb-0 col-7 cardSubtitle1">
                <span>{{(overViewData.totalTaxOrders) ? $func.formatCommaSepNum(overViewData.totalTaxOrders) : 0}}</span> Orders
              </p>
              <span class="align-center col-5 cardSubtitle2">
                <p>{{(overViewData.avgTaxes) ? $func.formatPrice(overViewData.avgTaxes) : 0}}</p> AOV
              </span>
            </div>
          </div>
        </div>
      </div>

      <div class="col-md-6 col-xl-3 overviewCard">
        <div class="card">
          <div class="card-body">
            <div class="float-end mt-2">
              <!-- <apexchart class="apex-charts" dir="ltr" width="70" height="40" :options="chartOptions" :series="series"></apexchart> -->
            </div>
            <div>
              <h4 class="cardTitle mb-1 mt-1">
                {{(overViewData.sumRejectedGrandTotal) ? $func.formatPrice(overViewData.sumRejectedGrandTotal) : 0}}
              </h4>
              <p class="text-muted mb-0">Rejected Orders</p>
            </div>
            <div class="row">
              <p class="text-muted mt-3 mb-0 col-7 cardSubtitle1">
                <span>{{(overViewData.totalRejectedOrders ) ? $func.formatCommaSepNum(overViewData.totalRejectedOrders)  : 0}}</span> Orders
              </p>
              <!-- <span class="align-center col-5">
                <p>{{(overViewData.avgTaxes) ? overViewData.avgTaxes : 0}}</p> AOV
              </span> -->
            </div>
          </div>
        </div>
      </div>

      <div class="col-md-2 cardBody">
        <div class="card">
          <div class="card-body">
            <div>
              <h4 class="mb-1 mt-1">
                {{(overViewData.sumNetPay) ? $func.formatPrice(overViewData.sumNetPay) : 0}}
                <!-- <span data-plugin="counterup">
                  <countTo :startVal="1000" :endVal="voucherData.redeemedVouchers" :duration="2000"></countTo>
                </span> -->
              </h4>
              <p class="text-muted mb-0">Amount Collected</p>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="row">
      <div class="col-lg-8 h-75">
        <div class="card">
          <div class="card-body">
            <h6 class="mb-4">Sales Graph</h6>
            <!-- Bar Chart -->
             <apexchart
                type="area"
                class="apex-charts"
                dir="ltr"
                height="339"
                :options="salesGraph.chartOptions"
                :series="salesGraph.series"
            ></apexchart>
<!--            <h5>Reports are temporarily unavailable, please reach out to support.</h5>-->
          </div>
        </div>
      </div>

      <div class="col-xl-4">
        <div class="card bg-themeYellow" style="background: #ffc60c;">
          <div class="card-body">
            <div class="row align-items-center">
              <div class="col-sm-8">
                <p class="text-white font-size-18">
                  <b v-if="salesCount.todaysOrder > 0">Congratulations</b> Today
                  you got {{ salesCount.todaysOrder }} new orders
                  <i class="mdi mdi-arrow-right"></i>
                </p>
                <div class="mt-4">
                  <router-link :to="{ name: 'ordersTable' }">
                    <a class="btn waves-effect waves-light" href="javascript: void(0);" style="color: #f3766a; background-color: white; border-color: white;">View Orders!</a>
                  </router-link>
                </div>
              </div>
              <div class="col-sm-4">
                <div class="mt-4 mt-sm-0">
                  <img alt class="img-fluid" src="@/assets/images/setup-analytics-amico.svg" />
                </div>
              </div>
            </div>
          </div>
          <!-- end card-body-->
        </div>
        <!-- end card-->
        <!-- <SellingProduct /> -->
        <div class="col-lg-12">
          <div class="card">
            <div class="card-body">
              <h4 class="card-title mb-4">Customer Ratings</h4>
              <p style="margin: 50px; text-align: center">Coming Soon</p>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="row">
      <div class="col-lg-6 h-75">
        <div class="card">
          <div class="card-body">
            <h6 class="mb-4">Top Selling Products</h6>
            <!-- Bar Chart -->
          <apexchart
                class="apex-charts"
                height="350"
                type="bar"
                dir="ltr"
                :series="topSellingProducts.series"
                :options="topSellingProducts.chartOptions"
            ></apexchart>
<!--            <h5>Reports are temporarily unavailable, please reach out to support.</h5>-->
          </div>
        </div>
      </div>

      <div class="col-lg-6">
        <div class="card">
          <div class="card-body">
            <h6 class="mb-4">
              Latest Orders
              <router-link :to="{ name: 'manageOrders' }">
                <button class="btn btn-themeYellow" style="position: absolute; right: 10px">
                  Manage Orders
                </button></router-link
              >
            </h6>
            <div class="table-responsive col-md-12">
              <table class="table table-striped mb-0" style="margin-top: 15px">
                <thead style="font-weight: 500">
                <tr>
                  <td>Order ID</td>
                  <td>Billing Name</td>
                  <td>Date</td>
                  <td>Total</td>
                </tr>
                </thead>
                <tbody v-for="data in latestOrder" :key="data.id">
                <tr>
                  <td>
                    <b>{{ data.orderID }}</b>
                  </td>
                  <td>{{ data.userName }}</td>
                  <td>{{ data.date }}</td>
                  <td class="align-right">₹{{ data.totalSum }}</td>
                </tr>
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
    </div>
  </Layout>
</template>
<script>
import Vue from 'vue';
import Layout from "../../layouts/main";
import PageHeader from "@/components/page-header";
import appConfig from "@/app.config";
import Multiselect from "vue-multiselect";
import DatePicker from "vue2-datepicker";
import {barChart} from "./data";
import VueSweetalert2 from 'vue-sweetalert2';
import 'sweetalert2/dist/sweetalert2.min.css';

Vue.use(VueSweetalert2);
export default {
  page: {
    title: "Dashboard",
    meta: [
      {
        name: "description",
        content: appConfig.description,
      },
    ],
  },
  components: {
    Layout,
    PageHeader,
    Multiselect,
    DatePicker,
  },
  data() {
    return {
      title: "Dashboard",
      items: [
        {
          text: "CloudKitch",
        },
        {
          text: "Dashboard",
          active: true,
        },
      ],
      isLoading: 0,
      cityArr: [],
      city: "",
      brandArr: [],
      brandData: "",
      countryArr: [],
      country: "",
      daterange: [], //[new Date(),new Date()],
      barChart: barChart,
      topSellingProducts: [],
      latestOrder: [],
      salesCount: [],
      salesGraph: [],
      branches: [],
      branchID: "",
      overViewData:[],
      restBranchID: this.$storageData.profile.restBranchID,
      //// noti /////
      firebaseServerKey: "AAAAqzbfXMw:APA91bGMYtIq2DwvxMlueTKMNmMDclMc1PZ-Eom0u-bnSkedj0JTp2Cmvo4Dex9g3OwH62F5Xx7P-0tT78M8DyFucmSWE3pV3KWFmN45s_-Y9e5FGYCl3Dp-nBnttYQWRGyG3YXPcyTD",
      userProfile: {},
      askForPermission: false,
      userToken: null,

      // stats data
      series: [
        {
          data: [25, 66, 41, 89, 63, 25, 44, 20, 36, 40, 54],
        },
      ],
      chartOptions: {
        fill: {
          colors: ["#5b73e8"],
        },
        chart: {
          type: "bar",
          sparkline: {
            enabled: true,
          },
        },
        plotOptions: {
          bar: {
            columnWidth: "50%",
          },
        },
        labels: [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11],
        xaxis: {
          crosshairs: {
            width: 1,
          },
        },
        tooltip: {
          fixed: {
            enabled: false,
          },
          x: {
            show: false,
          },
          y: {
            title: {
              formatter: function () {
                return "";
              },
            },
          },
          marker: {
            show: false,
          },
        },
      },
      growthChartOptions: {
        fill: {
          colors: ["#f1b44c"],
        },
        chart: {
          type: "bar",
          sparkline: {
            enabled: true,
          },
        },
        plotOptions: {
          bar: {
            columnWidth: "50%",
          },
        },
        labels: [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11],
        xaxis: {
          crosshairs: {
            width: 1,
          },
        },
        tooltip: {
          fixed: {
            enabled: false,
          },
          x: {
            show: false,
          },
          y: {
            title: {
              formatter: function () {
                return "";
              },
            },
          },
          marker: {
            show: false,
          },
        },
      },
      orderseries: [70],
      orderRadial: {
        fill: {
          colors: ["#34c38f"],
        },
        chart: {
          sparkline: {
            enabled: true,
          },
        },
        dataLabels: {
          enabled: false,
        },
        plotOptions: {
          radialBar: {
            hollow: {
              margin: 0,
              size: "60%",
            },
            track: {
              margin: 0,
            },
            dataLabels: {
              show: false,
            },
          },
        },
      },
      customerseries: [55],
      customerRadial: {
        fill: {
          colors: ["#5b73e8"],
        },
        chart: {
          sparkline: {
            enabled: true,
          },
        },
        dataLabels: {
          enabled: false,
        },
        plotOptions: {
          radialBar: {
            hollow: {
              margin: 0,
              size: "60%",
            },
            track: {
              margin: 0,
            },
            dataLabels: {
              show: false,
            },
          },
        },
      },
      /// stats data end
    };
  },
  mounted() {
    //   if(this.$storageData.profile.empTypeID==2 || this.$storageData.profile.empTypeID==11){
    this.getRestaurantCountry();
    //this.getRestaurantDashboard();
    //   }else{
    // this.getRestaurantDashboard();
    //   }
  },
  methods: {
    getRestaurantBrandsByCompanyID() {
      this.axios
          .post(this.$loggedRole+"/getRestaurantBrandsByCompanyID", {
            companyID: this.$storageData.profile.companyID,
          })
          .then((response) => {
            this.brandArr = response.data.data;
            // this.brandData = this.brandArr ? this.brandArr[0] : "";
            // this.readRestaurantBranchData();
          });
    },
    getRestaurantCountry() {
      this.axios
          .post(this.$loggedRole+"/getRestaurantCountry", {
            restID: this.$storageData.profile.restID,
            company_id:this.$storageData.profile.companyID,
            // isDashboard: 1, // for all dropdown in only dashboards
          })
          .then((response) => {
            this.countryArr = response.data.data;
            // this.country = this.countryArr ? this.countryArr[0] : "";
            // this.getRestaurantCities();
          });
    },
    getRestaurantCities() {
      this.axios
          .post(this.$loggedRole+"/getRestaurantCities", {
            restID: this.$storageData.profile.restID,
            country: this.country ? this.country.country : "",
            company_id:this.$storageData.profile.companyID,
            // isDashboard: 1, // for all dropdown in only dashboards
          })
          .then((response) => {
            this.cityArr = response.data.data;
            // this.city = this.cityArr ? this.cityArr[0] : "";
            // if (this.$storageData.profile.empTypeID == 11) {
            //   this.getRestaurantBrandsByCompanyID();
            // } else {
            //   this.readRestaurantBranchData();
            // }
          });
    },
    async getRestaurantDashboard() {
      this.isLoading = 1;
          this.axios
              .post(this.$loggedRole+"/restaurantDashboard", {
                restID: this.brandData && this.brandData != "" ? this.brandData.restID : this.$storageData.profile.restID,
                restBranchID: this.branchID && this.branchID != "" && this.branchID.restBranchID > 0 ? this.branchID.restBranchID : this.$storageData.profile.restBranchID,
                // this.$storageData.profile.restBranchID
                empTypeID: this.$storageData.profile.empTypeID,
                daterange: this.daterange,
                loginTypeID:this.$storageData.login_type,
                paymentType : "",
                orderType : "",
                country: this.country ? this.country.country : "",
                city: this.city ? this.city.city : "",
                companyID:this.$storageData.profile.companyID,
              })
              .then((result) => {
                this.isLoading = 0;
                // if (result.data.status == 200) {
                  this.salesCount = result.data.salesData;
                   this.topSellingProducts = result.data.topSellingProducts;
                  this.latestOrder = result.data.latestOrder;
                this.salesGraph = result.data.salesGraph.salesGraph;
                  this.overViewData = result.data.salesOverView;
                // }
                // else {
                //   this.$swal({
                //       title: 'Access Denied.',
                //       text: result.data.message !== undefined ? result.data.message : 'Something went wrong',
                //       icon: 'error'}).then(
                //       result.data.defaultUrl!==undefined ? this.$router.push(result.data.defaultUrl):""
                //   );
                // }
              })
              .catch((error)=>{
                this.isLoading = 0;
               this.$swal({
                text: error.response.data.message,
                icon: 'error'})

      });
    },
    readRestaurantBranchData() {
      this.axios
          .post(this.$loggedRole+"/getRestaurantBranchByRestID", {
            restID: this.brandData != "" ? this.brandData.restID : this.$storageData.profile.restID,
            restBranchID: 0,
            empTypeID: this.$storageData.profile.empTypeID,
            city: this.city ? this.city.city : "",
            // isDashboard: 1, // for all dropdown in only dashboards
          })
          .then((response) => {
            this.branches = response.data.data;
            // this.branchID = this.branches ? this.branches[0] : "";
            this.restBranchID = this.branchID ? this.branchID.restBranchID : "";
            // this.getRestaurantDashboard();
          });
    },
    onchangeBranch() {
      this.restBranchID = this.branchID && this.branchID != "" && this.branchID.restBranchID > 0 ? this.branchID.restBranchID : this.$storageData.profile.restBranchID;
      // this.getRestaurantDashboard();
    },

    applyFilter(){
      this.restBranchID = this.branchID && this.branchID != "" && this.branchID.restBranchID > 0 ? this.branchID.restBranchID : this.$storageData.profile.restBranchID;
      this.getRestaurantDashboard();
    },
    clearFilter() {
      // this.restBranchID = this.branchID && this.branchID != "" && this.branchID.restBranchID > 0 ? this.branchID.restBranchID : this.$storageData.profile.restBranchID;
      this.country = "";
      this.city = "";
      this.brandData = "";
      this.branchID = "";
      this.daterange = [];
      this.salesCount=[];
      this.latestOrder=[];
      this.overViewData=[];
      // this.getRestaurantDashboard();
    },

  },
};
</script>
<style scoped>
.cardBody
{
  min-width: 180px!important;
  width:fit-content!important;
}
.cardTitle
{
  font-size:18px;
}
.cardSubtitle1 span
{
  color:#A4827D;
  font-weight:500;
  font-size:15.5px;
}
.cardSubtitle2 p
{
  color:#F3766A;
  font-weight:500;
  font-size:17px;
  margin:0;
}
.overviewCard
{
  padding: 0 5px !important;
}
</style>